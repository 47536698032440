<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-19 11:13:41
 * @LastEditTime: 2019-11-09 16:35:34
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="favorite-container">
    <page-top :title="$t('restaurant_submenu.BA20D2@Privilege_grouping')">
      <span class="fa fa-tags fa-2x"></span>
    </page-top>
    <el-card>
      <section style="overflow:hidden;">
        <KtButton
          :label="$t('public.DC9752@btn_add')"
          style="float:right;"
          class="table-btn btn-gray"
          :perm="3949"
          @click="add"
        ></KtButton>
      </section>
      <el-table
        :data="tableData"
        :row-class-name="$tableRowClassName"
        v-loading="loading"
        :element-loading-text="$t('public.4ABAB1@loading')"
        style="width: 100%;margin-top:20px;"
      >
        <el-table-column
          prop="group_id"
          :label="$t('supplier_power_grouping.C9B87D@grouping_id')"
          align="center"
        ></el-table-column>
        <el-table-column prop="group_name" :label="$t('aboutus.119D08@side_bar')" align="center"></el-table-column>
        <el-table-column
          prop="is_system"
          :label="$t('supplier_power_grouping.A70BF8@super_administrator')"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{scope.row.is_system == 'YES'?$t('aboutus.980E0D@yes'):$t('aboutus.A03514@no')}}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('supplier_power_grouping.0E5FF1@operation')"
          width="250px"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.allow_modify">
              <KtButton
                v-if="scope.row.allow_modify"
                type="primary"
                :label="$t('public.B6D57F@btn_modify')"
                :perm="2443"
                @click="modify(scope.row)"
              ></KtButton>
              <KtButton
                :label="$t('public.1FBB5E@btn_delete')"
                type="danger"
                :perm="3145"
                @click="deleteRow(scope.row.group_id)"
              ></KtButton>
            </div>
            <div v-else>{{$t('supplier_power_grouping.25BFF5@super_administrator_notmodify')}}</div>
          </template>
        </el-table-column>
      </el-table>

      <section class="flex-end mt20">
        <el-pagination
          @size-change="handleSizeChange"
          :page-sizes="[5,10,15]"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </section>
    </el-card>

    <!--树形复选框 -->
    <el-dialog
      :title="$t('supplier_submenu.B77F3A@authority_management')"
      center
      width="60%"
      :visible.sync="dialogVisible"
    >
      <el-form :rules="rules" ref="form" :model="dialogForm" label-width="180px">
        <el-form-item style="width:65%;" :label="$t('aboutus.119D08@side_bar')" prop="name">
          <el-input v-model="dialogForm.name"></el-input>
        </el-form-item>
        <div>
          <section v-for="parent in menuData" :key="parent.id" class="module-container">
            <el-checkbox-group v-model="checkedTreeId">
              <div class="module-title">
                <section>
                  <el-checkbox
                    :disabled="parent.children|isDisabled"
                    :value="parent.id"
                    :label="parent.id"
                  >{{parent.name_hk}}</el-checkbox>
                </section>
                <!-- @change="((val) => {topChange(val, parent)})" -->
              </div>
              <div class="menu-container" v-for="menu in parent.children" :key="menu.id">
                <span class="menu-title" v-if="menu.type!='btn'">
                  <el-checkbox
                    :value="menu.id"
                    :label="menu.id"
                    @change="((val) => {menuChange(val, menu,parent)})"
                  >{{menu.name_hk}}</el-checkbox>
                  <el-tag v-if="menu.type=='btn'" style="display:inline-block;" size="mini">btn</el-tag>
                </span>
                <div style="display:none;">
                  <span class="btn-name" v-for="btn in menu.children" :key="btn.id">
                    <el-checkbox
                      style="display:inline-block;"
                      :value="btn.id"
                      :label="btn.id"
                      @change="((val) => {btnChange(val, menu,btn)})"
                    >{{btn.name_hk}}</el-checkbox>
                    <el-tag style="display:inline-block;" size="mini">btn</el-tag>
                  </span>
                </div>
              </div>
            </el-checkbox-group>
          </section>
        </div>
        <el-form-item style="overflow:hidden;padding:20px 0;">
          <el-button
            @click="confirmDialogForm('form')"
            style="float:right;"
            type="primary"
            size="small"
          >{{$t('public.3B45A2@confirm')}}</el-button>
          <el-button
            style="float:right;margin-right:20px"
            size="small"
            @click="dialogVisible = false;"
          >{{$t('public.836FD4@cancel')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import pageTop from "../../components/PageTop/PageTop";
import KtButton from "../../components/ktButton/KtButton";
import { log } from "util";
export default {
  data() {
    return {
      select: {
        search: ""
      },
      tableData: [],
      total: 0,
      currentPage: 1,
      pageSize: 10,
      options: [],
      addModify: 1,
      dialogVisible: false,
      dialogForm: {
        name: "",
        shop_id: 0,
        type: ""
      },
      rules: {
        name: {
          required: true,
          message: this.$t("aboutus.B003A6@This_is_required"),
          trigger: "blur"
        }
      },
      row: [],
      menuData: [],

      checkedTreeId: [],
      btn: [],
      sub_menu: [],
      click: 0,
      loading: false
    };
  },
  filters: {
    isDisabled: function(value) {
      if (value.length && value[0].type != "btn") {
        return true;
      }
    }
  },
  watch: {},
  components: {
    pageTop,
    KtButton
  },
  created() {
    this.getTableData();
    this.getLocal();
  },
  methods: {
    // 实现了二级全选的结果
    btnChange(val, menu, btn) {
      // 勾选了btn，就push其父menu
      if (this.checkedTreeId.indexOf(menu.id) == -1) {
        this.checkedTreeId.push(menu.id);
      }
      // 取消勾选
      if (!val) {
        let btnIds = [];
        menu.children.forEach(btn => {
          btnIds.push(btn.id);
        });
        let result = btnIds.filter(
          item => this.checkedTreeId.indexOf(item) != -1
        );
        // if (!result.length) {
        //   let index = this.checkedTreeId.indexOf(btn.parentId);
        //   this.checkedTreeId.splice(index, 1);
        // }
      }
    },

    // 一級下面的btn的相關操作
    menuChange(val, self, top) {
      if (self.type == "btn") {
        // 一級
        let btns = [];
        top.children.forEach(btn => {
          btns.push(btn.id);
        });
        if (val) {
          //自己選擇後，父級也要選擇
          if (this.checkedTreeId.indexOf(self.parentId) == -1) {
            this.checkedTreeId.push(self.parentId);
          }
          console.log("1级check==>", this.checkedTreeId);
        } else {
          // 自己以及兄弟都取消後，父級也取消
          let result = btns.filter(
            item => this.checkedTreeId.indexOf(item) != -1
          );
          // if (!result.length) {
          //   // 沒有兄弟了了，取消父級
          //   let index = this.checkedTreeId.indexOf(self.parentId);
          //   this.checkedTreeId.splice(index, 1);
          // }
        }
      } else {
        // 二級
        let btnsId = [];
        self.children.forEach(btn => {
          btnsId.push(btn.id);
        });
        if (!val) {
          //取消
          btnsId.forEach(btn => {
            let index2 = this.checkedTreeId.indexOf(btn);
            if (index2 >= 0) {
              this.checkedTreeId.splice(index2, 1);
            }
          });
          console.log("erjiquxiao==>", this.checkedTreeId);
        } else {
          // 選擇
          btnsId.forEach(btn => {
            this.checkedTreeId.push(btn);
          });
          console.log("erji==>", this.checkedTreeId);
        }
      }
    },
    // 获取权限组列表
    getTableData() {
      this.loading = true;
      this.$http
        .groupingGetdata({
          size: this.pageSize,
          page: this.currentPage
        })
        .then(res => {
          this.loading = false;
          console.log(res);
          this.tableData = res.data.list;
          this.total = res.data.sum;
        });
    },
    // 获取选择器
    changeSelect() {
      this.$http.getShopSelector().then(res => {
        this.options = res.data;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    // 打开弹出框
    add() {
      this.checkedTreeId = [];
      this.addModify = 0;
      this.dialogVisible = true;
      // if (this.dialogForm.name) {
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
      // }
    },
    // 点击修改获取展示数据
    modify(row) {
      this.addModify = 1;
      this.dialogVisible = true;
      this.row = row;
      this.$nextTick(() => {
        this.$refs["form"].resetFields();
      });
      this.$http
        .groupingGetInfo({
          group_id: row.group_id
        })
        .then(res => {
          this.checkedTreeId = res.data.route_id_obj.concat(
            res.data.btn_id_obj
          );
          this.dialogForm.name = res.data.group_name;
          this.dialogForm.shop_id = res.data.shop_id;
          this.dialogForm.type = res.data.shop_id;
        });
    },
    recursion(data, idArr) {
      let that = this;
      data.forEach(v => {
        idArr.forEach(j => {
          if (j == v.id) {
            if (v.type == "btn") {
              console.log(that.btn, j);
              if (that.btn.indexOf(j) == -1) {
                that.btn.push(j);
              }
            } else {
              if (that.sub_menu.indexOf(j) == -1) {
                that.sub_menu.push(j);
              }
            }
          }
        });
        if (v.children.length) {
          that.recursion(v.children, idArr);
        }
      });
    },
    // 弹出框确认
    confirmDialogForm(formName) {
      this.btn = [];
      this.sub_menu = [];
      this.recursion(this.menuData, this.checkedTreeId);
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (this.addModify) {
            this.$http
              .updateAuthGroup({
                shop_id: this.dialogForm.shop_id,
                group_id: this.row.group_id,
                group_name: this.dialogForm.name,
                route_id_json: JSON.stringify(this.sub_menu) || "",
                btn_id_json: JSON.stringify(this.btn) || ""
              })
              .then(res => {
                this.dialogVisible = false;
                this.getTableData();
              });
          } else {
            this.$http
              .addAuthGroup({
                shop_id: this.dialogForm.shop_id,
                group_name: this.dialogForm.name,
                route_id_json: JSON.stringify(this.sub_menu) || "",
                btn_id_json: JSON.stringify(this.btn) || ""
              })
              .then(res => {
                this.dialogVisible = false;
                this.getTableData();
              });
          }
        } else {
          return false;
        }
      });
    },
    //删除
    deleteRow(id) {
      console.log(id);
      this.$confirm(
        this.$t("shop-goods.2ECFD0@Confirm_delete"),
        this.$t("public.553159@tips"),
        {
          confirmButtonText: this.$t("public.3B45A2@confirm"),
          cancelButtonText: this.$t("public.836FD4@cancel")
        }
      ).then(() => {
        this.$http
          .delAuthGroup({
            group_id: id
          })
          .then(res => {
            if (this.tableData.length == 1 && this.currentPage > 1) {
              this.currentPage -= 1;
            }
            this.getTableData();
          });
      });
    },

    getLocal() {
      this.$http.getAuthJsonList().then(res => {
        console.log(res);
        let tableData = res.data;
        if (tableData.length) {
          this.tableTreeDdata = tableData;
          this.menuData = tableData;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.form-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  section {
    display: flex;
    align-items: center;
  }
}
.pagination-container {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  .pagination {
    float: right;
  }
}

// tree
.module-container {
  background-color: #f5f5f5;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f5f5f5;
  .module-title {
    // display: inline-block;
    margin-bottom: 0;
  }
  .menu-container {
    display: flex;
    padding-left: 30px;
    .menu-title {
      // display: inline-block;
      > label {
        width: 130px;
      }
    }

    .btn-name {
      // display: inline-block;
      > label {
        width: 110px;
      }
      > span {
        margin-right: 10px;
      }
    }
  }
}
</style>

   